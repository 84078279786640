import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import axios from '@/service/axios';
import store from '@/store';
import { getLoginUser, getUserCase } from '../service/api/userApi';
import { getSpaceCurationInfoNotHeader } from '../service/api/profileApi';
import { getCookie } from '../utils/Utils';
import spaceManageCommon from '../hoc/spaceManageCommon';
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

const getSpaceSubscribe = async userId => {
  try {
    const { data } = await getUserCase(userId);
    const { resultCd, result } = data;
    if (resultCd === '0000') {
      return result;
    } else {
      return null;
    }
  } catch (error) {
    console.error('error : ', error);
    return null;
  }
};

const routes = [
  {
    path: '/',
    name: 'Layout3',
    component: () => import('@/layout/Layout3.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/ReNewalHome.vue'),
        beforeEnter: (to, from, next) => {
          store.getters.isLogin ? next('/myspace') : next();
        }
      },
      {
        path: '/en',
        name: 'HomeEn',
        component: () => import('@/views/ReNewalHomeEn.vue')
      },
      {
        path: '/update',
        name: 'Update',
        component: () => import('@/views/Update.vue')
      },
      {
        path: '/noticelist',
        name: 'NoticeListView',

        component: () => import('@/views/notice/noticeListView.vue')
      },
      {
        path: '/noticedetail/:id',
        name: 'NoticeDetailView',

        component: () => import('@/views/notice/noticeDetailView.vue')
      },
      {
        path: '/faqlist',

        name: 'FaqListView',
        component: () => import('@/views/faq/faqListView.vue')
      }
    ]
  },
  {
    path: '/stplat',
    redirect: '/stplat/service',
    name: 'HomePolicy',
    component: () => import('@/views/HomePolicy.vue'),

    children: [
      {
        path: '/stplat/service',
        name: 'HomeService',
        component: () => import('@/views/policy/Service.vue')
      },
      {
        path: '/stplat/privacy',
        name: 'HomePrivacy',
        component: () => import('@/views/policy/Privacy.vue')
      }
    ]
  },
  {
    path: '/auth/:toMail/:authCode',
    name: 'EmailAuth',
    component: () => import('@/views/EmailAuth.vue')
  },
  {
    path: '/nice',
    name: 'NiceAuth',
    component: () => import('@/views/NiceAuth.vue')
  },
  {
    path: '/pay/returnBill',
    name: 'ReturnBill',
    component: () => import('@/views/ReturnBill.vue')
  },
  {
    path: '/experience',
    component: () => import(`@/layout/ExperienceLayout.vue`),
    children: [
      {
        path: '/experience',
        name: 'Experience',
        component: () => import(`@/views/experience/ExperienceView.vue`),
        props: true
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/layout/Layout2.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        props: true
      },
      {
        path: '/signUp',
        name: 'SignUp',
        component: () => import('@/views/signUp/SignUp.vue')
      },
      {
        path: '/socialAgree',
        name: 'SocialAgree',
        component: () => import('@/views/signUp/SocialAgree.vue'),
        props: true
      },
      {
        path: '/socialCallback',
        name: 'socialCallback',
        component: () => import('@/views/signUp/SocialCallback.vue'),
        props: true
      },
      {
        path: '/socialVaild',
        name: 'SocialVaild',
        component: () => import('@/views/signUp/SocialVaild.vue'),
        props: true
      },
      {
        path: '/socialIdCheck',
        name: 'SocialIdCheck',
        component: () => import('@/views/signUp/SocialIdCheck.vue'),
        props: true
      },
      {
        path: '/socialFinished',
        name: 'SocialFinished',
        component: () => import('@/views/signUp/SocialFinished.vue'),
        props: true
      },
      {
        path: '/signUp2',
        name: 'SignUpFinished',
        props: true,
        beforeEnter: (to, from, next) => {
          store.getters.isLogin ? next('/myspace') : next();
        },
        component: () => import('@/views/signUp/SignUpFinished.vue')
      },
      {
        path: '/findId',
        name: 'FindId',
        props: true,
        component: () => import('@/views/findId/FindId.vue')
      },
      {
        path: '/findIdFinished',
        name: 'FindIdFinished',
        props: true,
        component: () => import('@/views/findId/FindIdFinished.vue')
      },
      {
        path: '/findPass',
        name: 'FindPass',
        component: () => import('@/views/findPw/FindPass.vue')
      },
      {
        path: '/changePass',
        name: 'ChangePass',
        component: () => import('@/views/findPw/ChangePass.vue')
      },
      {
        path: '/changePassFinished',
        name: 'ChangePassFinished',
        component: () => import('@/views/findPw/ChangePassFinished.vue')
      }
    ]
  },
  {
    path: '/introview',
    name: 'Intro',
    props: true,
    component: () => import('@/views/slotView/introView.vue')
  },
  {
    path: '/player',
    name: 'Player',
    component: () => import('@/layout/Layout4.vue'),
    props: true,
    redirect: '/playerview',
    children: [
      {
        path: '/mysound',
        props: true,
        component: () => import('@/components/modal/Player/mysound/MySound.vue')
      },
      {
        path: '/playerview',
        name: 'PlayerView',
        props: true,
        component: () => import('@/views/player/PlayerView.vue'),
        beforeEnter: async (to, from, next) => {
          const limitList = ['user_004699', 'user_000236'];
          const email = VueCookies.get('email');
          const aToken = VueCookies.get('aToken');
          const spaceId = VueCookies.get('spaceId');
          const userId = VueCookies.get('userId');
          const findIdx = limitList.findIndex(item => item === userId);
          const isSeondongUser = VueCookies.get('route') === 'seongdong';
          if (!spaceId) {
            const userId = getCookie('userId');
            const result = await getSpaceSubscribe(userId);
            if (result !== null && result !== undefined) {
              if (store.state.division === 'Building') {
                return next('/myspaceBuilding');
              }
              next({
                params: {
                  userspacesubscribe: result
                }
              });
              return false;
            }
            store.commit('setChannelMusicInfo', null);
            store.commit('setChannelId', '');
            next('/myspace');
            return false;
          } else if (email != null && aToken != null) {
            const headers = { 'X-AUTH-TOKEN': aToken };
            axios
              .post('/api/menu/selectNoticeCheck', { userId }, { headers })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  const result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                  result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);
                  if (spaceId != null) {
                    // 쿠키에 spaceId가 있을때
                    axios
                      .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                      .then(res2 => {
                        if (res2.data.resultCd === '0000') {
                          const result2 = res2.data.result;
                          if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                            // 음악 멈춤
                            const video = document.getElementById('video');
                            if (video != null) video.pause();
                            store.commit('setPlay', false);
                            // 쿠키 spaceId 삭제
                            // VueCookies.remove('spaceId');
                            /// /vex spaceId, spaceNm 삭제
                            // store.commit('setSpaceId', null);
                            // store.commit('setSpaceNm', null);
                            if (store.state.division === 'Building') {
                              next('/myspaceBuilding');
                            } else {
                              if (findIdx > -1) {
                                next('/playerZero');
                              } else {
                                if (isSeondongUser === true) {
                                  next('/seongdongplayer');
                                } else {
                                  next();
                                }
                              }
                            }
                          } else {
                            if (store.state.division === 'Building') {
                              next('/myspaceBuilding');
                            } else {
                              if (findIdx > -1) {
                                next('/playerZero');
                              } else {
                                if (isSeondongUser === true) {
                                  next('/seongdongplayer');
                                } else {
                                  next();
                                }
                              }
                            }
                          }
                        }
                      })
                      .catch(err2 => {
                        console.log(err2);
                      });
                  } else {
                    store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                  }
                }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            next('/login');
          }
        }
      },
      {
        path: '/spaceChoice',
        name: 'SpaceChoice',
        props: true,
        component: () => import('@/views/main/SpaceChoice.vue'),

        beforeEnter: (to, from, next) => {
          var email = VueCookies.get('email');
          var aToken = VueCookies.get('aToken');
          var spaceId = VueCookies.get('spaceId');
          if (store.getters.isLogin && store.state.division !== 'Building' && spaceId) {
            next('/myspace');
          }

          if (email != null && aToken != null) {
            var userId = VueCookies.get('userId');
            var headers = { 'X-AUTH-TOKEN': aToken };

            axios
              .post('/api/menu/selectNoticeCheck', { userId }, { headers })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                  result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                  if (spaceId != null) {
                    // 쿠키에 spaceId가 있을때
                    axios
                      .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                      .then(res2 => {
                        if (res2.data.resultCd === '0000') {
                          var result2 = res2.data.result;
                          if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                            // 음악 멈춤
                            const video = document.getElementById('video');
                            if (video != null) video.pause();
                            store.commit('setPlay', false);
                            store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                          } else {
                            store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                          }
                        }
                      })
                      .catch(err2 => {
                        console.log(err2);
                      });
                  } else {
                    store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                  }
                }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            next('/login');
          }
        }
      },
      {
        path: '/main',
        name: 'Main',
        props: true,
        component: () => import('@/views/main/Main.vue'),
        beforeEnter: (to, from, next) => {
          var email = VueCookies.get('email');
          var aToken = VueCookies.get('aToken');
          var spaceId = VueCookies.get('spaceId');
          if (email != null && aToken != null) {
            var userId = VueCookies.get('userId');
            var headers = { 'X-AUTH-TOKEN': aToken };

            axios
              .post('/api/menu/selectNoticeCheck', { userId }, { headers })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                  result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                  if (spaceId != null) {
                    // 쿠키에 spaceId가 있을때
                    axios
                      .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                      .then(res2 => {
                        if (res2.data.resultCd === '0000') {
                          var result2 = res2.data.result;
                          if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                            // 음악 멈춤
                            const video = document.getElementById('video');
                            if (video != null) video.pause();

                            store.commit('setPlay', false);
                            store.state.division === 'Building' ? next('/myspaceBuilding') : next('/myspace');
                          } else {
                            store.state.division === 'Building' ? next('/myspaceBuilding') : next('/myspace');
                          }
                        }
                      })
                      .catch(err2 => {
                        console.log(err2);
                      });
                  } else {
                    store.state.division === 'Building' ? next('/myspaceBuilding') : next('/myspace');
                  }
                }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            next('/login');
          }
        }
      },
      {
        path: '/myspace',
        component: () => import('@/layout/Layout.vue'),
        children: [
          {
            path: '/myspace',
            name: 'MySpace',
            component: () => import('@/views/myspace/MySpace.vue').then(module => spaceManageCommon(module.default)),
            props: true,
            beforeEnter: async (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                const { data: loginData } = await getLoginUser(email, headers);
                const { resultCd: loginResultCd, result: loginResult } = loginData;
                if (loginResultCd === '0000') {
                  store.commit('setDivision', loginResult.division);
                  store.commit('setRoute', loginResult.route ? loginResult.route : null);
                }
                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                              } else {
                                store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateStore',
            name: 'UpdateSpace',
            component: () =>
              import('@/views/myspace/MySpaceUpdateStore/UpdateSpace.vue').then(module =>
                spaceManageCommon(module.default)
              ),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                store.state.division === 'Building' ? next('/myspace/updateBuilding') : next();
                              } else {
                                store.state.division === 'Building' ? next('/myspace/updateBuilding') : next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next('/myspace/updateBuilding') : next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/curationsetting/:spaceid?/:curationid?',
            name: 'SettingCurationTime',
            component: () => import(`@/views/curation/SettingCuration.vue`),
            beforeEnter: (to, __, next) => {
              if (to.params.curationid) {
                getSpaceCurationInfoNotHeader(to.params.spaceid, to.params.curationid)
                  .then(res => {
                    const { resultCd } = res.data;
                    if (resultCd === '0000') {
                      next();
                    } else {
                      next('/myspace');
                    }
                  })
                  .catch(err => {
                    if (err.response) {
                      const { status } = err.response;
                      if (status !== 400) {
                        next('/myspace');
                      } else {
                        next();
                      }
                    } else {
                      next('/myspace');
                    }
                  });
              }
              next();
            }
          },
          {
            path: '/curationsettingtime/:spaceid?',
            name: 'SettingTimeView',
            component: () => import(`@/views/curation/SettingTimeView.vue`),
            props: true
          },
          {
            path: '/myspace/updateStoreCuration',
            name: 'UpdateCuration',
            component: () => import('@/views/myspace/MySpaceUpdateStore/UpdateCuration.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateStoreFranchise',
            name: 'Franchise',
            component: () =>
              import('@/views/myspace/MySpaceUpdateStore/Franchise.vue').then(module =>
                spaceManageCommon(module.default)
              ),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateStoreautoPlay',
            name: 'AutoPlay',
            component: () => import('@/views/myspace/MySpaceUpdateStore/AutoPlay.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateCarCuration',
            name: 'UpdateCurationCar',
            component: () => import('@/views/myspace/MySpaceUpdateCar/UpdateCurationCar.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateCarPurpose',
            name: 'CarPurpose',
            component: () => import('@/views/myspace/MySpaceUpdateCar/CarPurpose.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateFavorite',
            name: 'MySpaceUpdateFavorite',
            component: () => import('@/views/myspace/MySpaceUpdateFavorite.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateSelectChannel',
            name: 'MySpaceUpdateSelectChannel',
            component: () => import('@/views/myspace/MySpaceUpdateSelectChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateStore5',
            name: 'MySpaceUpdateStore5',
            props: true,
            component: () => import('@/views/myspace/MySpaceUpdateStore5.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/player/updateCuration',
            name: 'InstallablePlayerUpdateCuration',
            component: () => import('@/views/installablePlayer/InstallablePlayerUpdateCuration.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);
                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/autologin',
            name: 'AutoLogin',
            component: () => import('@/views/common/AutoLogin.vue')
          },
          {
            path: '/space/updateSearchChannel',
            name: 'MySpaceUpdateSearchChannel',
            component: () => import('@/views/myspace/MySpaceUpdateSearchChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateFindChannel',
            name: 'MySpaceUpdateFindChannel',
            component: () => import('@/views/myspace/MySpaceUpdateFindChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateCarPurpose',
            name: 'MySpaceUpdateCarPurpose',
            component: () => import('@/views/myspace/MySpaceUpdateCarPurpose.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/updateCarMood',
            name: 'MySpaceUpdateCarMood',
            component: () => import('@/views/myspace/MySpaceUpdateCarMood.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/notice/notice0',
            name: 'Notice0',
            component: () => import('@/views/notification/Notification0.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/notice/notice1',
            name: 'Notice1',
            props: true,
            component: () => import('@/views/notification/Notification1.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/policy',
            redirect: '/policy/service',
            name: 'Policy',
            component: () => import('@/views/policy/Policy.vue'),
            children: [
              {
                path: '/policy/service',
                name: 'Service',
                component: () => import('@/views/policy/Service.vue'),
                beforeEnter: (to, from, next) => {
                  var email = VueCookies.get('email');
                  var aToken = VueCookies.get('aToken');
                  var spaceId = VueCookies.get('spaceId');
                  if (email != null && aToken != null) {
                    var userId = VueCookies.get('userId');
                    var headers = { 'X-AUTH-TOKEN': aToken };

                    axios
                      .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                      .then(res => {
                        if (res.data.resultCd === '0000') {
                          var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                          result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                          if (spaceId != null) {
                            // 쿠키에 spaceId가 있을때
                            axios
                              .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                              .then(res2 => {
                                if (res2.data.resultCd === '0000') {
                                  var result2 = res2.data.result;
                                  if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                    // 음악 멈춤
                                    const video = document.getElementById('video');
                                    if (video != null) video.pause();

                                    store.commit('setPlay', false);
                                    next();
                                  } else {
                                    next();
                                  }
                                }
                              })
                              .catch(err2 => {
                                console.log(err2);
                              });
                          } else {
                            next();
                          }
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  } else {
                    next('/login');
                  }
                }
              },
              {
                path: '/policy/privacy',
                name: 'Privacy',
                component: () => import('@/views/policy/Privacy.vue'),
                beforeEnter: (to, from, next) => {
                  var email = VueCookies.get('email');
                  var aToken = VueCookies.get('aToken');
                  var spaceId = VueCookies.get('spaceId');
                  if (email != null && aToken != null) {
                    var userId = VueCookies.get('userId');
                    var headers = { 'X-AUTH-TOKEN': aToken };

                    axios
                      .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                      .then(res => {
                        if (res.data.resultCd === '0000') {
                          var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                          result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                          if (spaceId != null) {
                            // 쿠키에 spaceId가 있을때
                            axios
                              .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                              .then(res2 => {
                                if (res2.data.resultCd === '0000') {
                                  var result2 = res2.data.result;
                                  if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                    // 음악 멈춤
                                    const video = document.getElementById('video');
                                    if (video != null) video.pause();

                                    store.commit('setPlay', false);
                                    next();
                                  } else {
                                    next();
                                  }
                                }
                              })
                              .catch(err2 => {
                                console.log(err2);
                              });
                          } else {
                            next();
                          }
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  } else {
                    next('/login');
                  }
                }
              },
              {
                path: '/policy/marketing',
                name: 'Marketing',
                component: () => import('@/views/policy/Marketing.vue'),
                beforeEnter: (to, from, next) => {
                  var email = VueCookies.get('email');
                  var aToken = VueCookies.get('aToken');
                  var spaceId = VueCookies.get('spaceId');
                  if (email != null && aToken != null) {
                    var userId = VueCookies.get('userId');
                    var headers = { 'X-AUTH-TOKEN': aToken };

                    axios
                      .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                      .then(res => {
                        if (res.data.resultCd === '0000') {
                          var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                          result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                          if (spaceId != null) {
                            // 쿠키에 spaceId가 있을때
                            axios
                              .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                              .then(res2 => {
                                if (res2.data.resultCd === '0000') {
                                  var result2 = res2.data.result;
                                  if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                    // 음악 멈춤
                                    const video = document.getElementById('video');
                                    if (video != null) video.pause();

                                    store.commit('setPlay', false);
                                    next();
                                  } else {
                                    next();
                                  }
                                }
                              })
                              .catch(err2 => {
                                console.log(err2);
                              });
                          } else {
                            next();
                          }
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  } else {
                    next('/login');
                  }
                }
              }
            ]
          },
          {
            path: '/space/insert',
            name: 'SpaceInsert',
            component: () => import('@/views/space/SpaceInsert.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                if (store.state.division === 'Building') {
                  /// myspaceBuilding
                  next('/myspaceBuilding');
                } else {
                  var userId = VueCookies.get('userId');
                  var headers = { 'X-AUTH-TOKEN': aToken };

                  axios
                    .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                    .then(res => {
                      if (res.data.resultCd === '0000') {
                        var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                        result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                        if (spaceId != null) {
                          // 쿠키에 spaceId가 있을때
                          axios
                            .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                            .then(res2 => {
                              if (res2.data.resultCd === '0000') {
                                var result2 = res2.data.result;
                                if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                  // 음악 멈춤
                                  const video = document.getElementById('video');
                                  if (video != null) video.pause();

                                  store.commit('setPlay', false);
                                  next();
                                } else {
                                  next();
                                }
                              }
                            })
                            .catch(err2 => {
                              console.log(err2);
                            });
                        } else {
                          next();
                        }
                      }
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStore',
            name: 'SpaceInsertStore',
            component: () =>
              import('@/views/space/SpaceInsertStore.vue').then(module => spaceManageCommon(module.default)),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                store.state.division === 'Building' ? next('/space/insertBuilding') : next();
                              } else {
                                store.state.division === 'Building' ? next('/space/insertBuilding') : next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next('/space/insertBuilding') : next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStoreColor',
            name: 'SpaceInsertStoreColor',
            component: () =>
              import(`@/views/space/SpaceInsertColor.vue`).then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertGenre',
            name: 'SpaceInsertGenre',
            component: () =>
              import(`@/views/space/SpaceInsertGenre.vue`).then(module => spaceManageCommon(module.default)),
            beforeEnter: (_, __, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },

          {
            path: '/space/insertStoreMaterial',
            name: 'SpaceInsertStoreMaterial',
            component: () =>
              import(`@/views/space/SpaceMaterial.vue`).then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStoreTarget',
            name: 'SpaceInsertStoreTarget',
            component: () =>
              import('@/views/space/SpaceInsertTarget.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStoreCountry',
            name: 'SpaceInsertStoreCountry',
            component: () =>
              import('@/views/space/SpaceInsertCountry.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStorePeriod',
            name: 'SpaceInsertStorePeriod',
            component: () =>
              import('@/views/space/SpaceInsertPeriod.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStoreFavorite/:spaceid?/:curationid?',
            name: 'SpaceInsertStoreFavorite',
            component: () =>
              import('@/views/space/SpaceInsertFavorite.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/selectChannel',
            name: 'SpaceInsertSelectChannel',
            component: () => import('@/views/space/SpaceInsertSelectChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertStore5/:curationid',
            name: 'SpaceInsertStore5',
            component: () => import('@/views/space/SpaceInsertStore5.vue'),
            beforeEnter: (to, from, next) => {
              const { name } = from;
              if (name === 'PlayerView') {
                next('/myspace');
              } else {
                var email = VueCookies.get('email');
                var aToken = VueCookies.get('aToken');
                var spaceId = VueCookies.get('spaceId');
                if (email != null && aToken != null) {
                  var userId = VueCookies.get('userId');
                  var headers = { 'X-AUTH-TOKEN': aToken };

                  axios
                    .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                    .then(res => {
                      if (res.data.resultCd === '0000') {
                        var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                        result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                        if (spaceId != null) {
                          // 쿠키에 spaceId가 있을때
                          axios
                            .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                            .then(res2 => {
                              if (res2.data.resultCd === '0000') {
                                var result2 = res2.data.result;
                                if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                  // 음악 멈춤
                                  const video = document.getElementById('video');
                                  if (video != null) video.pause();

                                  store.commit('setPlay', false);

                                  next();
                                } else {
                                  next();
                                }
                              }
                            })
                            .catch(err2 => {
                              console.log(err2);
                            });
                        } else {
                          next();
                        }
                      }
                    })
                    .catch(err => {
                      console.log(err);
                    });
                } else {
                  next('/login');
                }
              }
            }
          },
          {
            path: '/space/searchChannel',
            name: 'SpaceInsertSearchChannel',
            component: () => import('@/views/space/SpaceInsertSearchChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/findChannel',
            name: 'SpaceInsertFindChannel',
            component: () => import('@/views/space/SpaceInsertFindChannel.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/spacePayment',
            name: 'SpacePayment',
            component: () => import('@/views/space/SpacePayment.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/SpacePaymentFinished',
            name: 'SpacePaymentFinished',
            props: true,
            component: () => import('@/views/space/SpacePaymentFinished.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/mymusic',
            name: 'MyMusic',
            component: () => import('@/views/mymusic/MyMusic.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/mymusicComment',
            name: 'MyMusicComment',
            component: () => import('@/views/mymusic/MyMusicComment.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/product',
            name: 'Product',
            component: () => import('@/views/space/SpaceInsert.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/payment',
            name: 'Payment',
            component: () => import('@/views/payment/Payment.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/card',
            name: 'Card',
            component: () => import('@/views/card/PaymentCard.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/coupon',
            name: 'Coupon',
            component: () => import('@/views/Coupon.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/event',
            name: 'Event',
            props: true,
            component: () => import('@/views/event/EventList.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/event/view',
            name: 'EventView',
            props: true,
            component: () => import('@/views/event/EventView.vue'),
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/cs',
            redirect: '/cs/faq',
            name: 'CS',
            component: () => import('@/views/cs/Cs.vue'),
            children: [
              {
                path: '/cs/faq',
                name: 'FAQ',
                component: () => import('@/views/cs/Faq.vue'),
                beforeEnter: (to, from, next) => {
                  var email = VueCookies.get('email');
                  var aToken = VueCookies.get('aToken');
                  var spaceId = VueCookies.get('spaceId');
                  if (email != null && aToken != null) {
                    var userId = VueCookies.get('userId');
                    var headers = { 'X-AUTH-TOKEN': aToken };

                    axios
                      .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                      .then(res => {
                        if (res.data.resultCd === '0000') {
                          var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                          result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                          if (spaceId != null) {
                            // 쿠키에 spaceId가 있을때
                            axios
                              .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                              .then(res2 => {
                                if (res2.data.resultCd === '0000') {
                                  var result2 = res2.data.result;
                                  if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                    // 음악 멈춤
                                    const video = document.getElementById('video');
                                    if (video != null) video.pause();

                                    store.commit('setPlay', false);
                                    next();
                                  } else {
                                    next();
                                  }
                                }
                              })
                              .catch(err2 => {
                                console.log(err2);
                              });
                          } else {
                            next();
                          }
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  } else {
                    next('/login');
                  }
                }
              },
              {
                path: '/cs/question',
                name: 'Question',
                component: () => import('@/views/cs/Question.vue'),
                beforeEnter: (to, from, next) => {
                  var email = VueCookies.get('email');
                  var aToken = VueCookies.get('aToken');
                  var spaceId = VueCookies.get('spaceId');
                  if (email != null && aToken != null) {
                    var userId = VueCookies.get('userId');
                    var headers = { 'X-AUTH-TOKEN': aToken };

                    axios
                      .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                      .then(res => {
                        if (res.data.resultCd === '0000') {
                          var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                          result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                          if (spaceId != null) {
                            // 쿠키에 spaceId가 있을때
                            axios
                              .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                              .then(res2 => {
                                if (res2.data.resultCd === '0000') {
                                  var result2 = res2.data.result;
                                  if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                    // 음악 멈춤
                                    const video = document.getElementById('video');
                                    if (video != null) video.pause();

                                    store.commit('setPlay', false);
                                    next();
                                  } else {
                                    next();
                                  }
                                }
                              })
                              .catch(err2 => {
                                console.log(err2);
                              });
                          } else {
                            next();
                          }
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  } else {
                    next('/login');
                  }
                }
              }
            ]
          },
          {
            path: '/myspaceBuilding',
            name: 'MySpaceBuilding',
            component: () =>
              import('@/views/buildingSpace/MySpaceBuilding.vue').then(module => spaceManageCommon(module.default)),
            beforeEnter: async (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };
                const { data: loginData } = await getLoginUser(email, headers);
                const { resultCd: loginResultCd, result: loginResult } = loginData;
                if (loginResultCd === '0000') {
                  store.commit('setDivision', loginResult.division);
                  store.commit('setRoute', loginResult.route ? loginResult.route : null);
                }
                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                store.state.division === 'Building' ? next() : next('/myspace');
                              } else {
                                store.state.division === 'Building' ? next() : next('/myspace');
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next() : next('/myspace');
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/space/insertBuilding',
            name: 'SpaceInsertBuilding',
            component: () =>
              import('@/views/buildingSpace/SpaceInsertBuilding.vue').then(module => spaceManageCommon(module.default)),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                store.state.division === 'Building' ? next() : next('/myspace');
                              } else {
                                store.state.division === 'Building' ? next() : next('/myspace');
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next() : next('/myspace');
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/updateBuilding',
            name: 'UpdateBuilding',
            component: () =>
              import('@/views/buildingSpace/UpdateBuilding.vue').then(module => spaceManageCommon(module.default)),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                store.state.division === 'Building' ? next() : next('/myspace');
                              } else {
                                store.state.division === 'Building' ? next() : next('/myspace');
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        store.state.division === 'Building' ? next() : next('/myspace');
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/connectSpeaker',
            name: 'ConnectSpeaker',
            component: () => import('@/views/buildingSpace/ConnectSpeaker.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/myspace/boardcastMusic',
            name: 'BoardcastMusic',
            component: () => import('@/views/buildingSpace/BoardcastMusic.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          },
          {
            path: '/brandMusic',
            name: 'SetBrandMusic',
            component: () => import('@/views/brandMusic/setBrandMusic.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              var email = VueCookies.get('email');
              var aToken = VueCookies.get('aToken');
              var spaceId = VueCookies.get('spaceId');
              if (email != null && aToken != null) {
                var userId = VueCookies.get('userId');
                var headers = { 'X-AUTH-TOKEN': aToken };

                axios
                  .post('/api/menu/selectNoticeCheck', { userId }, { headers })
                  .then(res => {
                    if (res.data.resultCd === '0000') {
                      var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                      result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                          .then(res2 => {
                            if (res2.data.resultCd === '0000') {
                              var result2 = res2.data.result;
                              if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                                // 음악 멈춤
                                const video = document.getElementById('video');
                                if (video != null) video.pause();

                                store.commit('setPlay', false);

                                next();
                              } else {
                                next();
                              }
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                next('/login');
              }
            }
          }
        ]
      },
      {
        path: '/seongdongplayer',
        name: 'SeongdongLibaryView',
        component: () => import('@/views/seongdongLibary/SeongdongLibaryView.vue'),
        beforeEnter: (to, from, next) => {
          const limitList = ['user_004699', 'user_000236'];
          const email = VueCookies.get('email');
          const aToken = VueCookies.get('aToken');
          const spaceId = VueCookies.get('spaceId');
          const userId = VueCookies.get('userId');

          const findIdx = limitList.findIndex(item => item === userId);
          if (!spaceId) {
            VueCookies.remove('spaceId');
            store.commit('setSpaceNm', null);
            store.commit('setChannelMusicInfo', null);
            store.commit('setChannelId', '');
            next('/myspace');
            return false;
          }

          if (email != null && aToken != null) {
            var headers = { 'X-AUTH-TOKEN': aToken };

            axios
              .post('/api/menu/selectNoticeCheck', { userId }, { headers })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
                  result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

                  if (spaceId != null) {
                    // 쿠키에 spaceId가 있을때
                    axios
                      .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                      .then(res2 => {
                        if (res2.data.resultCd === '0000') {
                          var result2 = res2.data.result;
                          if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                            // 음악 멈춤
                            const video = document.getElementById('video');
                            if (video != null) video.pause();

                            store.commit('setPlay', false);
                            if (store.state.division === 'Building') {
                              next('/myspaceBuilding');
                            } else {
                              if (findIdx > -1) {
                                next('/playerZero');
                              } else {
                                next();
                              }
                            }
                          } else {
                            if (store.state.division === 'Building') {
                              next('/myspaceBuilding');
                            } else {
                              if (findIdx > -1) {
                                next('/playerZero');
                              } else {
                                next();
                              }
                            }
                          }
                        }
                      })
                      .catch(err2 => {
                        console.log(err2);
                      });
                  } else {
                    store.state.division === 'Building' ? next('/myspaceBuilding') : next();
                  }
                }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            next('/login');
          }
        }
      }
    ]
  },
  {
    path: '/showroom/:spaceId',
    name: 'Showroom',
    component: () => import('@/views/showroom/SelectMood.vue'),
    beforeEnter: (to, from, next) => {
      var email = VueCookies.get('email');
      var aToken = VueCookies.get('aToken');
      var spaceId = VueCookies.get('spaceId');
      if (email != null && aToken != null) {
        var userId = VueCookies.get('userId');
        var headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/menu/selectNoticeCheck', { userId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
              result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

              if (spaceId != null) {
                // 쿠키에 spaceId가 있을때
                axios
                  .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                  .then(res2 => {
                    if (res2.data.resultCd === '0000') {
                      var result2 = res2.data.result;
                      if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                        // 음악 멈춤
                        const video = document.getElementById('video');
                        if (video != null) video.pause();

                        store.commit('setPlay', false);
                        next();
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err2 => {
                    console.log(err2);
                  });
              } else {
                next();
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/showroom/complete',
    name: 'ShowroomComplete',
    component: () => import('@/views/showroom/Complete.vue'),
    beforeEnter: (to, from, next) => {
      var email = VueCookies.get('email');
      var aToken = VueCookies.get('aToken');
      var spaceId = VueCookies.get('spaceId');
      if (email != null && aToken != null) {
        var userId = VueCookies.get('userId');
        var headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/menu/selectNoticeCheck', { userId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              var result = res.data.result.noticeCnt + res.data.result.commonNoticeCnt;
              result > 0 ? store.commit('setNoticeCount', result) : store.commit('setNoticeCount', 0);

              if (spaceId != null) {
                // 쿠키에 spaceId가 있을때
                axios
                  .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                  .then(res2 => {
                    if (res2.data.resultCd === '0000') {
                      var result2 = res2.data.result;
                      if (result2.spacePayAt !== 'Y' && result2.spacePayAt !== 'T') {
                        // 음악 멈춤
                        const video = document.getElementById('video');
                        if (video != null) video.pause();

                        store.commit('setPlay', false);
                        next();
                      } else {
                        next();
                      }
                    }
                  })
                  .catch(err2 => {
                    console.log(err2);
                  });
              } else {
                next();
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        next('/login');
      }
    }
  },
  {
    path: 'landing',
    name: 'LandingLayout',
    component: () => import('@/layout/LandingLayout.vue'),
    redirect: '/hyundai',
    children: [
      {
        path: '/hyundai',
        name: 'HyundaiLanding',
        component: () => import('@/views/landingLayout/HyundaiLanding.vue')
      },
      {
        path: '/kt',
        name: 'KTLanding',
        redirect: '/home',
        component: () => import('@/views/landingLayout/KTLanding.vue')
      },
      {
        path: '/store',
        name: 'StoreLanding',
        component: () => import('@/views/landingLayout/NewStoreLanding.vue')
      },
      {
        path: '/ktcollabo',
        name: 'KTCollaboLanding',
        component: () => import('@/views/landingLayout/KTCollaboLanding.vue')
      },
      {
        path: '/pass',
        name: 'KTPassLanding',
        component: () => import('@/views/landingLayout/KTPassLanding.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/NotFoundPage.vue')
  },
  {
    path: '/inspect',
    name: 'inspect',
    component: () => import('@/views/Inspect.vue')
  },
  {
    path: '/visitor/:spaceId',
    name: 'visitor',
    component: () => import('@/views/visitor/Visitor.vue')
  },
  {
    path: '/naverLogin',
    name: 'naverLogin',
    component: () => import('@/views/naverLogin.vue')
  },
  {
    path: '/callback',
    name: 'installablePlayerCallback',
    component: () => import('@/views/installablePlayer/InstallablePlayerCallback.vue')
  },
  {
    path: '/kt_galaxyevent',
    name: 'KTCollab',
    component: () => import(`@/views/landingLayout/KTCollab.vue`)
  },
  {
    path: '/kt_iphone',
    name: 'KTIphone',
    component: () => import(`@/views/landingLayout/KTIphone.vue`)
  },
  {
    path: '/hanwha_prairie',
    name: 'HanwhaPrairie',
    component: () => import(`@/views/hanwhaPrairie/HanwhaPrairie.vue`)
  },
  {
    path: '/launchingevent',
    name: 'launchingevent',
    component: () => import(`@/views/bridgePage/LaunchEvent.vue`)
  },
  {
    path: '/raidioevent1',
    name: 'RaidioEvent',
    component: () => import(`@/views/raidioevent/RaidioEvent.vue`)
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  const title = to.meta.title === undefined ? '어플레이즈 - 세상 모든 공간을 PLAY하라!' : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

export default router;
