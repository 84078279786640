import {
  cancleSubscriptionApi,
  createCurationInfoApi,
  createPreferMusicListApi,
  createSpaceInfoApi,
  deleteMySpaceInfoApi,
  disconnectSpaceNoticeApi,
  requestBrandDisConnectApi,
  updateCarPayAtApi,
  updateSpaceInfo,
  setSpaceColor,
  setSpaceMaterial,
  updateCurationInfoAllTemp,
  updateCurationAllTemp
} from '../service/api/profileApi';
import { videoObject } from '../assets/js/videoPlayer';
import { leaveChannel } from '@/assets/js/webcast';
import store from '../store';
export default function spaceManageCommon(componentName) {
  return {
    name: 'SpaceManageCommon',
    methods: {
      /**
       * @description 스페이스 삭제
       * @param {*} spaceId
       * @returns Prmoise
       */
      async deleteSpace(spaceId) {
        const cookieSpaceId = this.$cookies.get('spaceId');
        try {
          const { status } = await deleteMySpaceInfoApi(spaceId);
          if (status === 200) {
            if (spaceId === cookieSpaceId) {
              videoObject.onPause();
              store.commit('setPlay', false);
              store.commit('setSpaceId', null);
              store.commit('setSpaceNm', null);
            }

            return {
              resultMsg: '',
              isSuccess: true
            };
          }
        } catch (error) {
          if (error.response) {
            const { data, status } = error.response;
            const { resultMsg } = data;
            if (status === 400) {
              return {
                resultMsg,
                isSuccess: false
              };
            }
          }
        }
      },
      /**
       * @description 스페이스 재구독
       * @param {*} spaceType
       * @param {*} spaceId
       * @returns Prmoise
       */
      async resubScribeSpace(spaceType, spaceId) {
        if (spaceType === 'Building') {
          try {
            const { status, data } = await updateCarPayAtApi(spaceId);
            if (status === 200) {
              const { resultCd } = data;
              if (resultCd === '0000') {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } catch (error) {
            if (error.response) {
              console.log(error);
            }

            return false;
          }
        } else {
          this.$router.push({ name: 'SpacePayment' });
          this.$gatag.event('resubscribe');
          localStorage.setItem('spaceId', spaceId);
        }
      },
      /**
       * @description 스페이스 플레이어 재생
       * @param {*} spaceType
       * @param {*} spaceId
       * @param {*} spaceNm
       * @returns Prmoise
       */
      async spacePlay(spaceType, spaceId, spaceNm) {
        if (this.$store.state.isBrandMusic) {
          this.$BrandPlayer.onBrandPause();
          this.$store.commit('setClearBrandPlayerList', []);
          this.$store.commit('setBrandActiveIdx', -1);
          this.$store.commit('setIsBrandMusic', false);
          this.$store.commit('setIsBrandMusicPlay', false);
          this.$store.commit('setBrandMusicInfo', null);
        }

        this.$VideoPlayer.onPause();
        // channelId가 있을때
        if (this.$store.state.channelId !== '') await leaveChannel();
        this.$cookies.set('spaceId', spaceId);
        this.$store.commit('setMusicInfoInterval', null);
        this.$store.commit('setSpaceId', null);
        this.$store.commit('setSpaceNm', spaceNm);
        this.$store.commit('setPlay', false);
        this.$store.state.player.isAddedJobInitFreePlayer = false;

        this.$VideoPlayer.setVideoEle();
        this.$VideoPlayer.onMute(false);
        this.$VideoPlayer.setVolume(this.$store.state.volume);

        this.$router
          .push({
            name: 'PlayerView',
            params: { spaceState: 'changeSpace', spaceType: spaceType, setIsLoading: true }
          })
          .catch(() => {});
      },
      /**
       * @description 이어서 등록 하기
       * @param {*} spaceType
       * @param {*} spaceId
       * @returns Prmoise
       */
      async nextInsert(spaceType, spaceId) {
        localStorage.setItem('spaceId', spaceId);
        this.$store.commit('coupon/setCuponInfo', null);
        this.$store.commit('setSpaceNm', null);
        if (spaceType === 'Store') {
          this.$router.push({ name: 'SpaceInsertStore', params: { spaceId } });
        } else if (spaceType === 'Car') {
          this.$router.push({ name: 'SpaceInsertCar', params: { spaceId } });
        } else {
          this.$router.push({ name: 'SpaceInsertBuilding', params: { spaceId } });
        }
      },
      /**
       * @description 브랜드 연결 해제
       * @param {*} brandId
       * @param {*} spaceId
       */
      async disConnect(brandId, spaceId) {
        let spId = spaceId;
        if (spId == null) {
          spId = localStorage.getItem('spaceId');
        }
        try {
          const { status } = await requestBrandDisConnectApi(brandId, spId);
          if (status === 200) {
            return {
              isSuccess: true
            };
          } else {
            return {
              isSuccess: false
            };
          }
        } catch (error) {
          return {
            isSuccess: false
          };
        }
      },
      /**
       * @description 스페이스 구독해제
       * @param {*} spaceInfo
       */
      async undescribeSpace(spaceInfo = { spaceId: null }) {
        if (spaceInfo.spaceType === 'Building') {
          //
          try {
            const { status } = await cancleSubscriptionApi(spaceInfo.spaceId);
            if (status === 200) {
              disconnectSpaceNoticeApi(spaceInfo.spaceId);

              if (spaceInfo.spaceId === this.$cookies.get('spaceId')) {
                videoObject.onPause();
                this.$cookies.remove('spaceId');
                this.$store.commit('setSpaceId', null);
                this.$store.commit('setSpaceNm', null);
              }
              return true;
            } else {
              return false;
            }
          } catch (error) {
            if (error.response) {
              return false;
            }
          }
        }
        return true;
      },
      /**
       * @description 이어서 등록하기
       * @param {*} spaceType
       * @param {*} spaceId
       * @param {*} isRegistCard
       * @param {*} isTrialSpace
       */
      spacenextInsert(spaceType, spaceId, isRegistCard = false, isTrialSpace = false) {
        localStorage.setItem('spaceId', spaceId);
        isRegistCard || isTrialSpace
          ? this.$store.commit('setTrialStatus', false)
          : this.$store.commit('setTrialStatus', true);

        // 라우터 이동
        spaceType === 'Store'
          ? this.$router.push({ name: 'SpaceInsertStore', params: { spaceId: spaceId } })
          : this.$router.push({ name: 'SpaceInsertCar', params: { spaceId: spaceId } });
      },
      /**
       * @description 새롭게 등록하기
       * @param {*} spaceId
       */
      async newInsert(spaceType, spaceId, isRegistCard = false, isTrialSpace = false) {
        // 매장등록 이동 처리
        try {
          const { status, data } = await deleteMySpaceInfoApi(spaceId);
          const { resultCd } = data;
          if (resultCd === '0000' && status === 200) {
            localStorage.removeItem('spaceId');
            this.spacenextInsert(spaceType, spaceId, isRegistCard, isTrialSpace);
          }
        } catch (error) {
          if (error.response) {
            console.log(error);
          }
        }
      },
      /**
       * @description 마이스페이스 이동 처리
       * @param {*} spaceId
       */
      async linkMySpace(spaceId = null) {
        if (spaceId !== null) {
          try {
            const { status } = await deleteMySpaceInfoApi(spaceId);
            if (status === 200) {
              this.$router.push('/myspace');
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$router.push('/myspace');
        }
      },
      /**
       * @description 스페이스 정보 저장
       * @param {*} spaceInfo
       */
      async spaceinfoUpdate(spaceInfo = {}) {
        try {
          const { status } = await updateSpaceInfo(spaceInfo);
          if (status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          if (error.response) {
            console.log(error);
            return false;
          }
        }
      },
      /**
       * @description 스페이스 등록
       * @param {*} spaceType
       * @param {*} userId
       */
      async spaceinfoInsert(spaceType, userId, spaceInfo) {
        try {
          const { status, data } = await createSpaceInfoApi(userId, spaceType);
          if (status === 200) {
            const spaceId = data.result;
            localStorage.setItem('spaceId', spaceId);
            spaceInfo.spaceId = spaceId;
            await createCurationInfoApi(spaceId);
            await createPreferMusicListApi(spaceId);
            const { status: updateSpaceInfoStatus, data: updateSpaceInfoData } = await updateSpaceInfo(spaceInfo);
            if (updateSpaceInfoStatus === 200 && updateSpaceInfoData.resultCd === '0000') {
              return {
                isSuccess: true,
                resultMsg: ''
              };
            } else {
              return {
                isSuccess: false,
                resultMsg: '오류가 발생하였습니다'
              };
            }
          } else {
            return {
              isSuccess: false,
              resultMsg: '오류가 발생하였습니다'
            };
          }
        } catch (error) {
          if (error.response) {
            console.log('spaceinfoInsert error : ', error);
            if (error.response.config.url.includes('/api/profile/updateSpaceInfo')) {
              const { data } = error.response;
              const { resultCd, resultMsg } = data;
              if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
                return {
                  isSuccess: false,
                  resultMsg: resultMsg
                };
              }
            }
            return {
              isSuccess: false,
              resultMsg: '오류가 발생하였습니다'
            };
          }
        }
      },
      /**
       * @description 색상 임시 저장
       * @param {*} color
       */
      async tempSaveColor(spaceId, color) {
        try {
          await setSpaceColor(spaceId, color);
          return true;
        } catch (error) {
          if (error.response) {
            console.log(error);
          }
          return false;
        }
      },
      /**
       * @description 소재 임시 저장
       * @param {*} spaceId
       * @param {*} material
       */
      async tempSaveMaterial(spaceId, material) {
        try {
          await setSpaceMaterial(spaceId, material);
          return true;
        } catch (error) {
          if (error.response) {
            console.log(error);
          }
          return false;
        }
      },
      /**
       * @description 큐레이션 전체 저장
       * @param {*} curationInfo
       * @returns
       */
      async updateCurationInfo(curationInfo) {
        try {
          await updateCurationInfoAllTemp(curationInfo);
          const { status, data } = await updateCurationAllTemp(curationInfo);
          if (status === 200) {
            const { resultCd } = data;
            if (resultCd === '0000') {
              return {
                isSuccess: true,
                msg: ''
              };
            } else {
              return {
                isSuccess: false,
                msg: '오류가 발생하였습니다'
              };
            }
          }
        } catch (error) {
          if (error.response) {
            const { data } = error.response;
            const { resultCd, resultMsg } = data;
            if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
              return {
                isSuccess: false,
                msg: resultMsg
              };
            }
          }
          return {
            isSuccess: false,
            msg: '오류가 발생하였습니다'
          };
        }
      }
    },
    props: componentName.props,
    render(h) {
      return h(componentName, {
        props: {
          ...this.$props,
          deletespace: this.deleteSpace,
          resubscribespace: this.resubScribeSpace,
          spaceplay: this.spacePlay,
          nextinsert: this.nextInsert,
          disconnect: this.disConnect,
          undescribespace: this.undescribeSpace,
          spacenextinsert: this.spacenextInsert,
          newinsert: this.newInsert,
          spaceinfoupdate: this.spaceinfoUpdate,
          spaceinfoinsert: this.spaceinfoInsert,
          linkmyspace: this.linkMySpace,
          tempsavecolor: this.tempSaveColor,
          tempsavematerial: this.tempSaveMaterial,
          updatecurationinfo: this.updateCurationInfo
        }
      });
    }
  };
}
